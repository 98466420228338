<!--
 * @Author: your name
 * @Date: 2021-01-07 11:51:11
 * @LastEditTime: 2021-01-07 11:53:51
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \one\src\views\systeminformation\index.vue
-->
<template>
  <div class="allmsgclass">
    <navigation :msg="msg"></navigation>
    <div class="noticeclass">
      <div class="cle">
        <van-icon class="thecle" name="cross" />立即开启【通知】，精彩不容错过～
      </div>
      <div><button>去开启</button></div>
    </div>
    <div class="tl">
      <div>通知列表</div>
      <div>全部已读</div>
    </div>
    <div v-for="(item, index) in allmsg" v-bind:key="index" class="systemclass">
      <div class="sy1">
        <div><img src="../../assets/userss.png" /></div>
        <div>
          <div class="msg1">{{item.content}}</div>
          <div>{{item.title}}>></div>
        </div>
      </div>
      <div>{{item.add_time}}</div>
    </div>

    <loading @loaddom="loaddom" ref="loades"></loading>
  </div>
</template>
<script>
import loading from "../../components/loading/index";
export default {
  components: {
    loading,
  },
  data() {
    return {
      msg: "系统消息",
      active: 0,
      allmsg: "", //所有数据
    };
  },
  mounted() {
    //查询消息列表
    this.getmessage();
  },
  methods: {
    loaddom() {
      this.$refs.loades
        .loadhttpes("/firm/v1/Channel/message_list", {
          reqType: "userinfo",
          pageno: 1,
          limit: 15,
        })
        .then((res) => {
          
          this.allmsg = res.arraymsg;
        });
    },
    /**
     * 查询消息列表
     */
    getmessage() {
      this.$http
        .post("/firm/v1/Channel/message_list", {
          reqType: "userinfo",
          pageno: 1,
          limit: 15,
        })
        .then((res) => {
          
        });
    },
  },
};
</script>
<style scoped>
.allnomsg {
  margin-top: 1.5rem;
}
.nomsgthe {
  text-align: center;
  font-size: 0.24rem;
  color: #9f9f9f;
  margin-top: 0.56rem;
}
.nomsgclass {
  text-align: center;
}
.nomsgclass img {
  width: 2.27rem;
  height: 3.86rem;
}
.sy1 {
  display: flex;
  align-items: center;
}
.msg1 {
  font-size: 0.32rem;
  color: #333333;
}
.systemclass {
  font-size: 0.24rem;
  color: #9f9f9f;
  display: flex;
  justify-content: space-between;
  margin: 0 0.3rem;
  padding: 0.36rem 0;
  border-bottom-color: #dfdfdf;
  border-bottom-style: solid;
  border-bottom-width: 1px;
}
.systemclass img {
  width: 0.82rem;
  height: 0.82rem;
  margin-right: 0.22rem;
}
.tl {
  font-size: 0.24rem;
  display: flex;
  justify-content: space-between;
  margin: 0.2rem 0.32rem;
}
.allmsgclass {
  background-color: #ffffff;
  padding-bottom: 2rem;
}
.noticeclass {
  display: flex;
  font-size: 0.24rem;
  background-color: #fda47c;
  margin: 0.2rem 0.31rem;
  padding: 0.16rem 0.21rem;
  align-items: center;
  justify-content: space-between;
}
.noticeclass button {
  background-color: #ffe3d7;
  color: #f55715;
  border: none;
}
.cle {
  display: flex;
  align-items: center;
}
.thecle {
  margin-right: 0.24rem;
}
</style>